@use '../../base';

/*
 * Mobile / Vertical
 */
 @media all and (max-width: 800px) {

    .hero1-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: base.$site-color-base;
      animation: fadeIn 1.5s ease-in;
      height: 40rem;
  
      img {
        display: none;
      }
    }
  
    .hero1-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
  
      max-width: 15rem;
      padding: .5rem;
  
      background-color: base.$site-color-section;
      backdrop-filter: grayscale(50%) blur(5px);
      border-radius: 1rem;
  
      color: base.$color-light;
  
      p {
        font-size: base.$mobile-small-p;
      }
    }
  
    .hero1-quote {
      display: none;
    }
  
    .hero1-image {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  
    .hero1-section img {
      max-width: 20rem;
      max-height: 20rem;
      padding-left: 2rem;
    }
}


/*
 * Desktop / Horizontal
 */
 @media all and (min-width: 50rem) {
  
    /* HERO SECTION */
  
    .hero1-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      background-color: base.$site-color-section;
      animation: fadeIn 1.5s ease-in;
      height: 100vh;
    }
  
    .hero1-quote {
      min-width: 15rem;
      background-color: base.$site-color-section;
      color: base.$color-light;
      margin-right: 0px;
      padding: 1rem;
  
      border-left: 2px base.$color-light solid;
      animation: fadeIn 2s ease-in;
  
      h2 {
        font-size: base.$large-heading;
        font-style: italic;
      }
  
      h3 {
        color: base.$site-color-gray;
        font-size: base.$small-heading;
        font-style: italic;
        margin-left: 20px;
        margin-top: 2px;
        margin-bottom: 2px;
        padding: 5px;
      }
  
      h4 {
        color: base.$site-color-gray;
        font-style: italic;
        margin-left: 20px;
        margin-top: 0px;
        margin-bottom: 2px;
        padding: 5px;
      }
  
      p {
        font-size: base.$large-p;
      }
    }
  
    .hero1-content {
      display: flex;
      flex-direction: column;
      max-width: 30rem;
      margin-right: 5vh;
      color: base.$color-light;
  
      padding: 3rem;
      background-color: base.$site-color-section;
      backdrop-filter: base.$site-filter-section;
      border-radius: 1rem;
  
      h2 {
        font-size: base.$large-heading;
        margin: 0;
      }
  
      p {
        font-size: base.$large-p;
      }
    }
  
    .hero1-image {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  
    .hero1-section img {
      max-width: 20rem;
      max-height: 20rem;
      padding-left: 2rem;
    }
}