@use '../../base';

.project-tile-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-tile {
  display: flex;
  flex-direction: row;
  position: relative;

  border-radius: 1rem;
  border: 2px solid base.$site-color-dark;
}

.project-background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  width: 100%;
  height: 100%;

  -webkit-filter: grayscale(80%) opacity(70%);
  -moz-filter: grayscale(80%) opacity(70%);
  -o-filter: grayscale(80%) opacity(70%);
  -ms-filter: grayscale(80%) opacity(70%);
  filter: grayscale(80%) opacity(70%);

  background-size: cover;
  background-position: center;
}

.project-details {
  z-index: 1;
}

.project-info {
  filter: drop-shadow(.3rem .5rem .2rem base.$color-black);
}

.project-blurb {
  display: flex;
  flex-direction: column;

  background-color: base.$color-black-light-transparent;
}

.project-img {
  z-index: 1;
  filter: drop-shadow(.3rem .5rem .2rem base.$color-black);
}

.project-tile-links {
  margin-left: auto;
  margin-right: auto;
}

/**
 * Mobile / Vertical
 */
@media all and (max-width: 50rem) {
  .project-tile-container {
    padding: 2rem 0rem;
    width: 100%;
  }
  
  .project-tile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 5px;  
    padding: 1rem;
    width: 20rem;
    min-height: 50vh;
    
    transition: all 0.5s ease; /* back to normal */
  }

  .project-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 1rem;

    h4 {
      font-size: base.$mobile-small-heading;
      margin: 0;
    }
  }

  .project-img img {
    width: 7rem;

    margin: 1rem;
  }

  .project-info {
    display: flex;
    flex-direction: column;
  }

  .project-blurb {
    width: 15rem;
    padding: 1rem;
    border-radius: .3rem;

    p {
      font-size: base.$mobile-small-p;
    }
  }

  .project-tile-links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 10rem;
      height: 3rem;
      margin: .25rem;
      text-align: center;
      text-decoration: none;

      color: base.$color-light;
      font-family: base.$font-heading;
      background-color: base.$color-black;
      border: 1px solid base.$site-color-dark-alt;
    }
  }
}

/**
 * Desktop / Horizontal
 */
@media all and (min-width: 50rem) {
  .project-tile-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .project-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: base.$site-color-light;
    color: base.$color-light;
    border-radius: 5px;

    width: 40rem;
    height: 30rem;
    padding: 1rem;
    margin: 1rem;
    transition: all 0.5s ease; /* back to normal */

    p {
      font-size: base.$small-p;
    }

    h4 {
      font-size: base.$small-heading;
      margin: 0;
    }

    img {
      transition: all 0.4s ease; /* zoom in */
    }
  }

  .project-tile:hover {
    transform: translate3D(0, -1px, 0) scale(1.03); /* move up slightly and zoom in */
    -webkit-box-shadow: 9px 9px 0px -2px base.$color-red-dark;
    box-shadow: 9px 9px 0px -2px base.$color-red-dark;
    transition: all 0.4s ease; /* zoom in */

    img {
      transform: translate3D(0, -1px, 0) scale(1.075);
      filter: drop-shadow(.5rem .75rem .5rem base.$color-black);
      transition: all 0.4s ease; /* zoom in */
    }
  }

  .project-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-top: 1px solid base.$color-almost-black;

    padding: 1rem 0rem;
  }

  .project-blurb {
    width: 20rem;
    padding: 1rem;
    border-radius: .3rem;
  }

  .project-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35rem;
    padding: 1rem;
  }

  .project-img {
    width: 10rem;
    img {
      width: 100%;
    }
  }

  .project-tile-links {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 7rem;
      height: 3rem;
      margin: .5rem;
      text-align: center;
      text-decoration: none;
      transition: all 0.4s ease;

      color: base.$color-light;
      font-family: base.$font-heading;
      background-color: base.$color-black;
      border: 1px solid base.$site-color-dark-alt;
    }

    a:hover {
      background-color: base.$color-red-dark;
      color: base.$color-light;
      border-radius: 5px;
      transition: all 0.4s ease; /* zoom in */
      cursor: pointer;
    }
  }
}
