@use '../../base';

.project-tile-large-icons {
  background-color: base.$color-black;
  margin: 0;
  z-index: 9;
}

.project-tile-large-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-tile-large {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.project-tile-large-blurb {
  background-color: base.$color-black-light-transparent;
  filter: drop-shadow(.3rem .5rem .2rem base.$color-black);
  border-radius: .2rem;
}

.project-tile-large-details {
  background-color: base.$color-black-light-transparent;
  filter: drop-shadow(.3rem .5rem .2rem base.$color-black);
}

.project-tile-large-background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  -webkit-filter: grayscale(80%) opacity(70%);
  -moz-filter: grayscale(80%) opacity(70%);
  -o-filter: grayscale(80%) opacity(70%);
  -ms-filter: grayscale(80%) opacity(70%);
  filter: grayscale(80%) opacity(70%);
}

.project-tile-large-screenshot {
  img {
    filter: opacity(70%) grayscale(50%) drop-shadow(.3rem .5rem .4rem base.$color-black);
    transition: all 0.4s ease;
  }
}


/**
 * Mobile / Vertical
 */
@media all and (max-width: 50rem) {
  .project-tile-large {
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    min-height: 120vh;

    margin: 0rem 0rem;
  }

  .project-tile-large-heading {
    text-align: center;
    font-size: base.$mobile-large-heading;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  .project-tile-large-blurb {
    margin: 1rem 0rem;
    padding: 1rem;
    border-radius: .5rem;
  }

  .project-tile-large-icons {
    position: sticky;
    top: 59px;

    padding: .4rem 0rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
  }

  .project-tile-large-icons img {
    max-width: 3rem;
    margin-right: .5rem;
  }

  .project-tile-large-content {
    width: 20rem;
    min-height: 100vh;
  }

  .project-tile-large h2 {
    font-size: base.$mobile-small-heading;
  }

  .project-tile-large-detail-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .project-tile-large-details {
    border-radius: .5rem;
    padding: 1rem;
  }

  .project-tile-large-screenshot {
    display: block;

    margin: 2rem 0;

    img {
      width: 20rem;
    }
  }
}

/**
 * Desktop / Horizontal
 */
@media all and (min-width: 50rem) {
  .project-tile-large {
    align-items: center;

    width: 85%;
    min-height: 110vh;
  }

  .project-tile-large-heading {
    text-align: center;
    font-size: base.$large-heading;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  .project-tile-large-description p {
    font-weight: 100;
    color: base.$color-light;
  }

  .project-tile-large-blurb {
    border-radius: .5rem;
    padding: 1rem;
    margin-top: 2rem;
  }

  .project-tile-large-icons {
    position: sticky;
    top: 57px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 0px;
    padding: 1rem;

    width: 100%;
  }

  .project-tile-large-icons img {
    max-width: 7rem;
    max-height: 4rem;

    margin-right: 1rem;
  }

  .project-tile-large-details {
    border-radius: .5rem;
    padding: 1rem;
  }

  .project-tile-large-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40rem;
    min-height: 40rem;
  }

  .project-tile-large h2 {
    font-size: base.$small-heading;
  }

  .project-tile-large-detail-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .project-tile-large-screenshot {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1rem;
  }

  .project-tile-large-screenshot img {
    max-width: 19rem;
    border-radius: .5rem;
  }

  .project-tile-large-screenshot img:hover {
    filter: opacity(90%) grayscale(20%) drop-shadow(1rem 2rem 3rem base.$color-black);
    transition: all 0.4s ease;
  }
}
