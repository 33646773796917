@use '../../../base';

.skill-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  //flex: 1;
  flex-direction: column;
  //flex-basis: 33%;
}

/**
 * Mobile / Vertical
 */
@media all and (max-width: 50rem) {
  .project-skills {
    display: flex;
    flex-direction: column;
  }

  .project-skills-heading {
    font-size: 24px;
  }

  .project-tech-icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .skill-icon {
    width: 4rem;
    height: 5rem;
    //border: 1px solid base.$color-light-gray;
    border-radius: .5rem;
    margin: .5rem;
  }

  .project-tech-icons img {
    max-width: 3rem;
    max-height: 3rem;
    border-radius: 5px;
    filter: grayscale(25%);
  }

  .skill-icon h5 {
    margin: .5rem 0 0 0;
    font-family: base.$font-heading;
    font-weight: 100;
    font-size: xx-small;
    text-align: center;
  }
}

/**
 * Desktop / Horizontal
 */
@media all and (min-width: 50rem) {
  .project-skills {
    display: flex;
    flex-direction: column;
  }

  .project-skills-heading {
    font-size: 30px;
  }

  .project-tech-icons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .skill-icon {
    width: 5rem;
    height: 7rem;
    //border: 1px solid base.$color-light-gray;
    border-radius: .5rem;
    margin: .5rem;

    transition: all 0.4s ease;

    h5 {
      margin: .25rem 0 0 0;
      font-family: base.$font-heading;
      font-weight: 100;
      text-align: center;
      text-wrap: nowrap;

      font-size: 0;
      opacity: 0%;
      transition: all 0.4s ease;
    }

    img {
      max-width: 3rem;
      max-height: 3rem;
      border-radius: 5px;
  
      filter: grayscale(70%) drop-shadow(.2rem .3rem .2rem base.$color-black);
  
      transition: all 0.4s ease;
    }
  }

  .skill-icon:hover {

    h5 {
      font-size: x-small;
      opacity: 100%;
      transition: all 0.4s ease;
      text-wrap: nowrap;
    }

    img {
      //background-color: base.$color-red-dark;
      max-width: 4rem;
      max-height: 4rem;
      padding: .5rem;
      filter: grayscale(10%) drop-shadow(1rem 2rem 2rem base.$color-black);
    }

    transition: all 0.4s ease;
  }
}
