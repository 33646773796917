@use '../../base';

.tile {
  font-family: base.$font-heading;
  color: base.$color-light;
  border: 1px solid base.$site-color-dark-alt;
  border-radius: .2rem;
  filter: drop-shadow(.1rem .2rem .2rem base.$color-black);
  background-color: base.$color-black;
}

/**
 * MOBILE / VERTICAL
 */
@media all and (max-width: 50rem) {
  .tile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem 0rem;

    background-size: cover;
    background-position: center;

    a {
      text-decoration: none;
    }

    h2 {
      font-size: base.$mobile-large-heading;
      margin: 0;
    }
  }

  .tile-group {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .tile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 2rem;
    text-decoration: none;
    width: 150px;
    height: 45px;

    p {
      margin: 0;
    }
  }
}

/**
 * DESKTOP / HORIZONTAL
 */
@media all and (min-width: 50rem) {
  .tile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 475px;

    background-size: cover;
    background-position: center;

    a {
      text-decoration: none;
    }

    h2 {
      font-size: base.$large-heading;
      margin: 0;
    }
  }

  .tile-group {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
  }

  .tile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.4s ease;
    text-decoration: none;
    width: 200px;
    height: 65px;

    p {
      margin: 0;
    }
  }

  .tile:hover {
    transform: translate3D(0, -1px, 0) scale(1.03); /* move up slightly and zoom in */
    box-shadow: 8px 10px 10px rgba(39, 44, 49, 0.07),
      1px 6px 12px rgba(39, 44, 49, 0.04);
    background-color: base.$color-red-dark;
    color: base.$color-light;
    border-radius: 5px;
    transition: all 0.4s ease; /* zoom in */
    cursor: pointer;
  }
}
