@use '../../base';

input {
  color: base.$color-light;
  background-color: base.$color-black-light-transparent;
  border: none;
}

textarea {
  color: base.$color-light;
  background-color: base.$color-black-light-transparent;
  border: none;
}

/**
 * Mobile / Vertical
 */
@media all and (max-width: 50rem) {
  .contact-form-section {
    display: flex;
    padding: 5% 0% 2% 0%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-form-heading {
    font-size: base.$mobile-large-heading;
    padding-bottom: 1.5rem;
    margin: 0;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .contact-form-field {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
  }

  .contact-form-field-text-area {
    display: flex;
    flex-direction: column;
    font-size: 20px;

    textarea {
      font-family: base.$font-body;
    }
  }

  .contact-form-field-text-area label {
    margin: 20px 0px 10px 0px;
    font-size: base.$mobile-small-heading;
  }

  .contact-form-field label {
    min-width: 150px;
    text-align: center;
    margin-bottom: 5px;
    font-size: base.$mobile-small-heading;
  }

  .contact-form-field input {
    margin-left: 10px;
    font-size: base.$mobile-small-heading;
  }

  .contact-form-buttons {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-evenly;
    height: 100px;
  }

  .contact-form-submit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: base.$color-red;
    color: base.$color-almost-black;
    font-size: base.$large-p;
    transition: all 0.4s ease;
    padding: 0rem 1rem;
    text-decoration: none;
    width: 100px;
    height: 50px;
    border: none;
  }

  .contact-form-reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: base.$color-light;
    color: base.$color-almost-black;
    font-size: base.$large-p;
    transition: all 0.4s ease;
    padding: 0rem 1rem;
    text-decoration: none;
    width: 100px;
    height: 50px;
    border: none;
  }
}


/**
 * Desktop / Horizontal
 */
@media all and (min-width: 50rem) {
  .contact-form-section {
    display: flex;
    padding: 5% 0% 2% 0%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-form-heading {
    font-size: 50px;
    padding-bottom: 1.5rem;
    margin: 0;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .contact-form-field {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px;
  }

  .contact-form-field-text-area {
    display: flex;
    flex-direction: column;
    font-size: 20px;

    textarea {
      font-family: base.$font-body;
    }
  }

  .contact-form-field-text-area label {
    margin: 20px 0px 10px 0px;
    font-size: 20px;
  }

  .contact-form-field label {
    min-width: 150px;
    text-align: left;
    font-size: 20px;
  }

  .contact-form-field input {
    margin-left: 10px;
    font-size: 20px;
  }

  .contact-form-buttons {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;
    height: 100px;
  }

  .contact-form-submit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: base.$color-red;
    color: base.$color-light;
    font-size: base.$large-p;
    transition: all 0.4s ease;
    padding: 0rem 1rem;
    text-decoration: none;
    width: 150px;
    height: 50px;
    border: none;
  }

  .contact-form-submit:hover {
    transform: translate3D(0, -1px, 0) scale(1.03); /* move up slightly and zoom in */
    background-color: base.$color-red;
    color: base.$color-almost-black;
    border-radius: 5px;
    transition: all 0.4s ease; /* zoom in */
    cursor: pointer;
  }

  .contact-form-reset:hover {
    transform: translate3D(0, -1px, 0) scale(1.03); /* move up slightly and zoom in */
    background-color: base.$color-red;
    color: base.$color-light;
    border-radius: 5px;
    transition: all 0.4s ease; /* zoom in */
    cursor: pointer;
  }
  .contact-form-reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: base.$color-light;
    color: base.$color-almost-black;
    font-size: base.$large-p;
    transition: all 0.4s ease;
    padding: 0rem 1rem;
    text-decoration: none;
    width: 150px;
    height: 50px;
    border: none;
  }
}
