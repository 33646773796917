@use '../../base';

/**
 * Mobile / Vertical
 */
@media all and (max-width: 50rem) {
  header {
    height: 3rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0rem .3rem;
    background-color: base.$site-color-dark;
  }

  header {
    .header-brand {
      padding: 0rem;
      min-width: 14rem;
      h1 {
        font-size: base.$mobile-small-heading;
        padding: 0rem .5rem;
      }
    }
    .header-brand-img {
      width: 2rem;
      max-height: 2rem;
    }

    .sticky {
      position: sticky;
      top: 0px;
    }

    .nav-links {
      display: none;
    }
    .nav-menu {
      display: block;
    }

    nav {
      font-size: base.$mobile-small-heading;
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        color: base.$color-light;
        padding: 0rem .2rem;
        transition-duration: base.$transition-duration;
      }

      a:visited {
        color: base.$color-light;
      }

      a:hover {
        color: base.$color-red;
        transition-duration: base.$transition-duration;
      }
    }
  }
}


/** 
 * Desktop / Horizontal
 */
@media all and (min-width: 50rem) {
  header {
    height: 4rem;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem 1rem;
  }

  header {
    .nav-links {
      display: block;
    }
    .nav-menu {
      display: none;
    }

    nav {
      font-size: 1.3rem;
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        color: base.$color-light;
        padding: 0rem 1rem;
        transition-duration: base.$transition-duration;
      }

      a:visited {
        color: base.$color-light;
      }

      a:hover {
        color: base.$color-red;
        transition-duration: base.$transition-duration;
      }
    }
  }

  .sticky {
    position: sticky;
    top: 0px;
  }

  .header-brand {
    h1 {
      font-size: base.$small-heading;
      margin: 0;
      padding: 0rem 1rem;
    }
  }

  .header-main-link {
    padding: 0rem 1rem;
  }

  .header-brand-img {
    width: 3rem;
    max-height: 3rem;
  }
}

/* BASE */
.header {
  transition-duration: base.$transition-duration;
  font-family: base.$font-heading;
}
.header-main-link {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: base.$color-light;
}
.header-main-link:hover {
  color: base.$color-red;
  transition-duration: base.$transition-duration;
}
.header-brand {
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header-brand-img {
  position: relative;
  animation: flying 4s infinite;
  opacity: 90%;
}

/* BRAND IMAGE ANIMATION */

@keyframes flying {
  0% {
    left: 0px;
    top: 0px;
  }
  25% {
    left: 5px;
    top: 0px;
  }
  50% {
    left: 0px;
    top: 0px;
  }
  75% {
    left: 5px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}
