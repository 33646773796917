@use '../../base';

/**
 * MOBILE / VERTICAL
 */
 @media all and (min-width: 50rem) {
  .hamburger-header-links {
    display: block;
  }
  .bm-burger-button {
    display: none;
  }
}

/**
 * DESKTOP / HORIZONTAL
 */
@media all and (min-width: 50rem) {
  .hamburger-header-links {
    display: none;
  }
  .bm-burger-button {
    display: none;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 1.5rem;
  height: 1.5rem;
  right: .5rem;
  top: .5rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: base.$color-light;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  position: fixed;
  height: 24px;
  width: 24px;
  right: 10px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: base.$color-light;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: base.$site-color-dark;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #1d3557;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: base.$color-light;
  margin-bottom: 10px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.menu-item {
  text-decoration: none;
}

a.menu-item {
  font-size: 25px;
  padding: 5px 0px;
}

.bm-menu a {
  color: base.$color-light;
}

.bm-menu a:visited {
  color: base.$color-light;
}
