@use '../../base';

/**
 * Mobile / Vertical
 */
@media all and (max-width: 50rem) {
  .aboutSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 110vh;

    background-color: base.$color-almost-black;
    background-image: url("../../assets/background/pedalboard_scaled.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    animation: fadeIn 1.5s ease-in;
  }

  .aboutBlurb {
    width: 15rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0rem;
    padding: .5rem;

    background-color: base.$site-color-section;
    backdrop-filter: base.$site-filter-section;
    border-radius: 1rem;

    h2 {
      font-size: base.$mobile-large-heading;
      text-align: center;
    }

    p {
      text-align: justify;
      font-size: base.$mobile-small-p;

      margin: 1rem;
    }
  }

  .aboutServices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: base.$mobile-large-heading;
    }
  }

  .servicesList {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    
    width: 100%;
    padding: 0;

    background-image: url("../../assets/background/uma-kitty-gold_scaled.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

    list-style-type: none;
  }

  .servicesList a {
    width: 7rem;
    height: 3rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: base.$color-light;
    color: base.$color-almost-black;
    font-size: base.$mobile-large-p;
    margin: 1rem 0rem;
    padding: 0rem 1rem;
    text-decoration: none;
    text-align: center;
  }

  .servicesList li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    width: 15rem;
    padding: .5rem;
    margin: 1rem;

    border-radius: 5px;
    background-color: base.$site-color-section;
    backdrop-filter: grayscale(50%) blur(5px);
    border-radius: .5rem;

    h4 {
      font-size: base.$mobile-large-p;
    }
  }
}

/**
 * Desktop / Horizontal
 */
@media all and (min-width: 50rem) {
  .aboutSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 110vh;

    background-color: base.$color-almost-black;
    background-image: url("../../assets/background/pedalboard_scaled.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    animation: fadeIn 1.5s ease-in;
  }

  .aboutBlurb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40rem;
    padding: 1rem;

    background-color: base.$site-color-section;
    backdrop-filter: grayscale(50%) blur(5px);
    border-radius: 1rem;

    h2 {
      font-size: base.$large-heading;
    }

    p {
      text-align: center;
      font-size: base.$large-p;
    }
  }

  .aboutServices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: base.$large-heading;
    }

    p {
      text-align: center;
      font-size: base.$large-p;
    }
  }

  .servicesList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;

    width: 100%;
    padding: 0;

    background-image: url("../../assets/background/uma-kitty-gold_scaled.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }

  .servicesList a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: base.$color-light;
    color: base.$color-almost-black;
    font-size: base.$large-p;
    transition: all 0.4s ease;
    margin: 1rem 0rem;
    padding: 0rem 1rem;
    text-decoration: none;
    text-align: center;
    width: 150px;
    height: 65px;
  }

  .servicesList a:hover {
    transform: translate3D(0, -1px, 0) scale(1.03); /* move up slightly and zoom in */
    background-color: base.$color-red;
    color: base.$color-light;
    border-radius: 5px;
    transition: all 0.4s ease; /* zoom in */
    cursor: pointer;
  }

  .servicesList li {
    width: base.$desktop-min-section-width;
    padding: 1rem;
    margin: 2rem 0rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: base.$site-color-section;
    backdrop-filter: grayscale(50%) blur(5px);
    border-radius: 1rem;

    font-size: base.$large-p;
    transition: all 0.4s ease; /* zoom in */

    h4 {
      font-size: base.$large-p;
    }
  }

  .servicesList li:hover {
    transform: translate3D(0, -1px, 0) scale(1.03); /* move up slightly and zoom in */
    -webkit-box-shadow: 9px 9px 0px -2px base.$color-red-dark;
    box-shadow: 9px 9px 0px -2px base.$color-red-dark;
    transition: all 0.4s ease; /* zoom in */
  }
}
