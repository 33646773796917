@use '../../base';

.project-anchors-links {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: base.$color-light;
    
    font-family: base.$font-heading;
    background-color: base.$color-black;
    border: 1px solid base.$site-color-dark-alt;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.anchor {
  z-index: 99;
  filter: none;
}

.anchor-beard {
  width: 100%;
  min-height: 20rem;
  background-color: base.$site-color-dark;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-mini-container {
  margin-left: auto;
  margin-right: auto;

  width: 100%;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.project-tile-mini {
  display:flex;
  flex-direction: row;
  justify-content: center;

  transition: all 0.4s ease; /* zoom in */

  border: 1px solid base.$color-light;
  border-radius: .2rem;
  filter: drop-shadow(.1rem .2rem .2rem base.$color-black);

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    color: base.$color-light;
  }
}

/**
 * Mobile / Vertical
 */
@media all and (max-width: 50rem) {
  .proj-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-heading {
    background-image: url("../../assets/background/production.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    padding: 2rem;

    animation: fadeIn 1.5s ease-in;

    height: 100vh;
  }

  .project-anchors {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: base.$color-black-transparent;
    border-radius: 1rem;

    h2 {
      font-size: base.$mobile-large-heading;
      margin: 3rem 0rem 0rem 0rem;
      text-align: center;
    }
  }

  .project-anchors-links {
    padding: 2rem 0rem;

    a {
      font-size: base.$mobile-large-p;

      text-decoration: none;
      text-align: center;
      width: 15rem;
      height: 65px;
      margin: 1rem 0;
      border-radius: .2rem;
    }
  }

  .anchor {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: base.$site-color-dark;
    height: 50px;
    padding: .3rem 0rem;
    position: sticky;
    top: 0;
    margin: 0;
    width: 100%;
  }

  .project-tile-mini {
  
    width: 5rem;
    height: 5rem;
  
    margin: 1rem; 
  
    img {
      width: 4rem;
      opacity: 70%;
    }
  }
}


/**
 * Desktop / Horizontal
 */
@media all and (min-width: 50rem) {
  .proj-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

  .project-heading {
    background-image: url("../../assets/background/pedalboard_scaled.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    padding: 2rem;

    min-height: 100vh;

    animation: fadeIn 1.5s ease-in;
  }

  .project-anchors {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: base.$color-black-transparent;
    border-radius: 1rem;

    h2 {
      font-size: base.$large-heading;
      margin: 5rem 0rem 0rem 0rem;
      text-align: center;
    }
  }

  .project-anchors-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 5rem 0rem;

    a {
      font-size: base.$large-p;
      transition: all 0.4s ease;
      padding: 0rem 1rem;
      text-decoration: none;
      text-align: center;
      width: 150px;
      height: 65px;
    }

    a:hover {
      transform: translate3D(0, -1px, 0) scale(1.03); /* move up slightly and zoom in */
      background-color: base.$color-red-dark;
      color: base.$color-light;
      border-radius: 5px;
      transition: all 0.4s ease; /* zoom in */
      cursor: pointer;
    }
  }

  .anchor {
    text-align: center;
    background-color: base.$site-color-dark;
    padding: 1rem 0rem;
    position: sticky;
    top: 0;
    margin: 0;
    width: 100%;
  }

  .project-tile-mini {
    display:flex;
    flex-direction: row;
    justify-content: center;
  
    width: 7rem;
    height: 7rem;
  
    margin: 1rem; 
  
    transition: all 0.4s ease; /* zoom in */
  
    border: 1px solid base.$color-light-gray;
    border-radius: .2rem;
    filter: drop-shadow(.1rem .2rem .2rem base.$color-black);
  
    a {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  
      width: 100%;
      height: 100%;
      color: base.$color-light;
    }
  
    img {
      width: 5rem;
      filter: drop-shadow(.1rem .2rem .2rem base.$color-black) grayscale(50%) opacity(50%);
    }
  }
  
  .project-tile-mini:hover {
    transform: translate3D(0, -1px, 0) scale(1.03); /* move up slightly and zoom in */
    filter: drop-shadow(.3rem .5rem .5rem base.$color-black);
    transition: all 0.4s ease; /* zoom in */

    cursor: pointer;
  
    img {
      transform: translate3D(0, -1px, 0) scale(1.075);
      filter: drop-shadow(.5rem .75rem .5rem base.$color-black);
      opacity: 100%;
      transition: all 0.4s ease; /* zoom in */
    }
  }
}
