@use '../../base';
@include base.animation-mixin(fadeIn, 0, 1);

/*
 * Mobile / Vertical
 */
@media all and (max-width: 800px) {

  .projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}


/*
 * Desktop / Horizontal
 */
@media all and (min-width: 800px) {

  /* SERVICES SECTION */

  .projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .tile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: base.$color-almost-black;
    color: base.$color-light;

    h2 {
      font-size: base.$large-heading;
    }
  }
}
