.coming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-top: 100px;
    width: 300px;
    opacity: 0.5;
  }
}
