@use '../../base';

/**
 * Mobile / Vertical
 */
@media all and (max-width: 800px) {
  .contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 300px;

    background-color: base.$site-color-dark;
    background-size: cover;
    background-position: center;
    /*background-image: url("../../assets/background/ranier-alt.png");*/
  }

  .contact-button-group {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
  }

  .contact-heading {
    text-align: center;
    font-size: base.$mobile-large-heading;
    padding: 0% 3%;
  }
}

/**
 * Desktop / Horizontal
 */
@media all and (min-width: 800px) {
  .contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 475px;

    background-color: base.$site-color-dark;
    background-size: cover;
    background-position: center;
    /*background-image: url("../../assets/background/ranier-alt.png");*/
  }

  .contact-button-group {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
  }

  .contact-heading {
    text-align: center;
    font-size: base.$large-heading;
    margin-bottom: 40px;
    padding: 0% 3%;
  }
}
