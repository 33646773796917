@use 'base';

body {
  font-family: base.$font-body;
  font-weight: normal;

  display: flex;
  flex-direction: column;
  background-color: base.$site-color-dark;
  color: base.$color-light;
  margin: 0;
}

html,
body {
  height: 100%;
}

#root {
  flex: 1 0 auto;
}

h1 {
  font-family: base.$font-heading;
  filter: drop-shadow(.3rem .5rem .2rem base.$color-black);  
}
h2 {
  font-family: base.$font-heading;
  filter: drop-shadow(.3rem .5rem .2rem base.$color-black);
}
h3 {
  font-family: base.$font-heading;
  filter: drop-shadow(.2rem .3rem .1rem base.$color-black);
}
h4 {
  font-family: base.$font-heading;
  filter: drop-shadow(.2rem .3rem .1rem base.$color-black);
}

.button {
  color: base.$color-light;
    
  font-family: base.$font-heading;
  background-color: base.$color-black;
  border: 1px solid base.$site-color-dark-alt;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/**
 * MOBILE / VERTICAL
 */
@media all and (max-width: 50rem) {
  section {
    min-width: 15rem;
  }

  .button {
    
  }
}

/**
 * DESKTOP / HORIZONTAL
 */
@media all and (min-width: 50rem) {
  section {
    min-width: 30rem;
  }

  .button {
    transition: all 0.4s ease;
  }
  .button:hover {
    background-color: base.$color-red-dark;
    color: base.$color-light;
    border-radius: 5px;
    transition: all 0.4s ease;
    cursor: pointer;
  }
}